import styled, { css } from 'styled-components'

export default css`
  #all-stories-container {
    padding-top: 40px;
    padding-bottom: 40px;
    h1 {
      font-size: 5em;
      position: absolute;
      max-width: 410px;
      color: #636363;
      @media(max-width: 992px) {
        font-size: 4em;
      }
    }
  }
`

export const ItemContainer = styled.div`
  position: relative;
  &:before {
    position: absolute;
    content: '';
    display: block;
    left: 56px;
    height: calc(100% - 130px);
    border-right: 2px dashed #636363;
    z-index: -1;
    top: 130px;
    opacity: 0.2;
    @media(max-width: 992px) {
      left: 42px;
    }
  }
  @media(max-width: 530px) {
    margin-top: 120px;
    &:before {
      top: 25px;
      height: calc(100% - 25px);
    }
  }
`

export const Item = styled.section`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 120px 0;


  @media(max-width: 660px) {
    flex-direction: column;
    margin: 30px 0;
  }
`

export const PointerLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  @media(max-width: 530px) {
    margin-bottom: 70px;
  }
`

export const Pointer = styled.div`
  min-width: 115px;
  min-height: 115px;
  background: #544bad;
  border-top-left-radius: 470px;
  border-top-right-radius: 570px;
  border-bottom-right-radius: 430px;
  border-bottom-left-radius: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    display: block;
    font-size: 18px;
    font-weight: bold;
  }
  &.first {}
  &.second {
    transform: rotate(40deg);
    span {
      transform: rotate(-40deg);
    }
  }
  &.third {
    transform: rotate(80deg);
    span {
      transform: rotate(-80deg);
    }
  }

  @media(max-width: 992px) {
    min-width: 85px;
    min-height: 85px;
    span {
      font-size: 14px;
    }
  }
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px dotted grey;
  margin-right: 30px;
  margin-left: 20px;
  opacity: 0.3;
  @media(max-width: 660px) {
    display: none;
  }
`