import React from 'react'
import styled from 'styled-components'
import styles, { ItemContainer, Item, PointerLine, Pointer, Line } from './_styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

// layouts
import RootLayout from '@Layouts/root'

// sections
import SimpleHeader from '@Sections/simple-header'

// blocks
import StoryCard from '@Blocks/story-card'

// assets
import arnostekIMG from '@Images/arnost-head.png'

const getRandomBg = () => {
  const purple = '#544bad'
  const blue = '#4c95c2'
  const green = '#249da4'
  const colorsArray = [purple, blue, green]
  const myIndex = Math.floor(Math.random() * colorsArray.length)

  return colorsArray[myIndex]
}

const getRandomRotationClassName = () => {
  const classNamesArray = ['first', 'second', 'third']
  const myIndex = Math.floor(Math.random() * classNamesArray.length)
  
  return classNamesArray[myIndex]
}

const Page = styled.div`${styles}`
const Pribehy = () => {
  const title = 'Příběhy pacientů | Psírehabilitace.cz'
  const description = 'Máme za sebou několik úspěšných případů a terapií a to nejen pro psy! Přesvědčte se sami v našich video příbězích.'
  const url = 'https://www.psirehabilitace.cz/pribehy'
  const data = useStaticQuery(graphql`
  query StoriesAllQuery {
    allMarkdownRemark(limit: 1000, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            petName
            shortDescription
            petAvatar {
              publicURL
            }
            date
          }
        }
      }
    }
  }
`)

  return (
    <RootLayout headerType="disabled">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="" />
        <meta name="image" content={arnostekIMG} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={arnostekIMG} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="Mvdr. Dominika Bitomská" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={arnostekIMG} />
      </Helmet>
      <SimpleHeader />
      <Page>
        <Container id="all-stories-container">
          <Row>
            <Col>
            <h1>Příběhy Pacientů...</h1>
            <ItemContainer>
            {data.allMarkdownRemark.edges.map((el) => {
              const img = el.node.frontmatter.petAvatar?.publicURL || '/'
              const date = new Date(el.node.frontmatter.date)
              const month = date.getMonth()+1
              const year = date.getFullYear()
              const day = date.getDate()
              const background = getRandomBg()

              return (
                <Item>
                  <PointerLine>
                    <Pointer style={{ background }} className={getRandomRotationClassName()}>
                      <span>{day}. {month}.<br /> {year}</span>
                    </Pointer>
                    <Line style={{ borderColor: background }} />
                  </PointerLine>
                  <StoryCard
                    variant="big"
                    background={background}
                    petName={el.node.frontmatter.petName}
                    link={el.node.fields.slug}
                    imgSrc={img}
                    // date={`${day}. ${month}. ${year}`}
                  >
                    {el.node.frontmatter.shortDescription}
                  </StoryCard>
                </Item>
              )
            })}
            </ItemContainer>
     
            </Col>
          </Row>
        </Container>
      </Page>
    </RootLayout>
  )
}

export default Pribehy